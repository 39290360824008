import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import { v4 as uuid } from 'uuid'
import {
  cFooter,
  cFooterCol,
  cFooterSection,
  cLink,
  cList,
  cListItem,
  cHeading,
  cSocialBar,
  cSocialIcon,
  cSocialLink,
  cSocket,
  cCopyright,
  cSocketMenu,
  cSocketMenuList,
  cSocketMenuListItem,
} from './styles.module.scss'

import {
  Section,
  Heading,
  FlexboxRow,
  FlexboxCol,
  Link,
} from 'src/components/Base'

const FooterColumn = ({ heading, children }) => (
  <FlexboxCol className={cFooterCol} width={4}>
    <Heading className={cHeading} size={4} as="h4">
      {heading}
    </Heading>
    {children}
  </FlexboxCol>
)

const FooterMenu = ({ menuItems, menuListClass, menuItemClass }) => (
  <ul className={menuListClass}>
    {menuItems.map(({ path, label }) => (
      <li key={uuid()} className={menuItemClass}>
        <Link className={cLink} to={path}>
          {label}
        </Link>
      </li>
    ))}
  </ul>
)

const Footer = () => {
  const footerData = useStaticQuery(graphql`
    query footerQuery {
      allWp {
        nodes {
          themeGeneralSettings {
            atspFooterOptions {
              firstColumnHeading
              secondColumnHeading
              thirdColumnHeading
              footerCopyright
              socialMediaRepeater {
                link
                icon {
                  altText
                  localFile {
                    publicURL
                  }
                }
              }
            }
          }
        }
      }
      allWpPage(
        filter: {
          template: { templateName: { eq: "Template-Branche" } }
          language: { locale: { eq: "de_AT" } }
        }
      ) {
        nodes {
          title
          uri
          atspBranchOptions {
            branchColor
          }
        }
      }
      allWpMenu {
        nodes {
          menuItems {
            nodes {
              path
              label
            }
          }
          slug
        }
      }
    }
  `)

  const branches = footerData.allWpPage.nodes
  const menus = footerData.allWpMenu.nodes

  const companyMenu = menus.filter((menu) => menu.slug === 'companymenue')[0]
  const informationMenu = menus.filter(
    (menu) => menu.slug === 'footer-information'
  )[0]
  const socketMenu = menus.filter((menu) => menu.slug === 'socketmenu')[0]

  const { atspFooterOptions } = footerData.allWp.nodes[0].themeGeneralSettings

  return (
    <footer className={cFooter}>
      <Section className={cFooterSection}>
        <FlexboxRow>
          <FooterColumn heading={atspFooterOptions.firstColumnHeading}>
            <ul className={cList}>
              {branches.map(({ title, uri, atspBranchOptions: options }) => (
                <li key={uuid()} className={cListItem}>
                  <Link
                    className={cLink}
                    barColor={options.branchColor}
                    to={uri}
                  >
                    {title}
                  </Link>
                </li>
              ))}
            </ul>
          </FooterColumn>
          <FooterColumn heading={atspFooterOptions.secondColumnHeading}>
            <FooterMenu menuItems={informationMenu.menuItems.nodes} />
          </FooterColumn>
          <FooterColumn heading={atspFooterOptions.thirdColumnHeading}>
            <FooterMenu menuItems={companyMenu.menuItems.nodes} />
            <div className={cSocialBar}>
              {atspFooterOptions.socialMediaRepeater.map(({ icon, link }) => (
                <a
                  className={cSocialLink}
                  href={link}
                  target="_blank"
                  rel="noreferrer"
                  key={uuid()}
                >
                  <img
                    alt={icon.localFile.altText || 'socialIcon'}
                    className={cSocialIcon}
                    src={icon.localFile.publicURL}
                  />
                </a>
              ))}
            </div>
          </FooterColumn>
        </FlexboxRow>
        <div className={cSocket}>
          <div className={cCopyright}>{atspFooterOptions.footerCopyright}</div>
          <div className={cSocketMenu}>
            <FooterMenu
              menuItems={socketMenu.menuItems.nodes}
              menuListClass={cSocketMenuList}
              menuItemClass={cSocketMenuListItem}
            />
          </div>
        </div>
      </Section>
    </footer>
  )
}

FooterColumn.propTypes = {
  heading: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
}

FooterMenu.propTypes = {
  menuItems: PropTypes.array.isRequired,
  menuListClass: PropTypes.string,
  menuItemClass: PropTypes.string,
}

FooterMenu.defaultProps = {
  menuListClass: cList,
  menuItemClass: cListItem,
}

export default Footer
