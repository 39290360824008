import React, { useContext } from 'react'
import AniLink from 'gatsby-plugin-transition-link/AniLink'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import LanguageContext from 'src/context/language'
import { cLink, cHasBar, cHasArrows, cBar, cArrows } from './styles.module.scss'

const Link = ({
  children,
  className,
  barColor,
  to,
  hasArrows,
  external,
  ...props
}) => {
  const linkClasses = classNames({
    [cLink]: true,
    [cHasBar]: barColor,
    [cHasArrows]: hasArrows,
    [className]: !!className,
  })

  const languageContext = useContext(LanguageContext)

  // Drop language-slug if it comes with the link already
  // !TODO: add optional chaining later on
  if (to) {
    if (to.includes(languageContext.currentLangauge)) {
      to = to.replace(
        `/${languageContext.currentLangauge}_${languageContext.currentLocation}`,
        ''
      )
    }
  }

  const renderLinkChildren = () => {
    let _children = []

    if (barColor) {
      _children.push(<span className={cBar} style={{ background: barColor }} />)
    }
    if (hasArrows) {
      _children.push(<span className={cArrows}>»</span>)
    }

    _children.push(children)
    return _children
  }

  return (
    <>
      {external && (
        <a className={linkClasses} {...props}>
          {renderLinkChildren()}
        </a>
      )}

      {!external && (
        <AniLink
          cover
          duration={0.9}
          bg="#4D71B3"
          className={linkClasses}
          to={`/${languageContext.currentLangauge}-${languageContext.currentLocation}${to}`}
          {...props}
        >
          {renderLinkChildren()}
        </AniLink>
      )}
    </>
  )
}

Link.propTypes = {
  children: PropTypes.node.isRequired,
  to: PropTypes.string,
  barColor: PropTypes.string,
  hasArrows: PropTypes.boolean,
  external: PropTypes.boolean,
}

Link.propTypes = {
  barColor: undefined,
  hasArrows: false,
  external: false,
}

export default Link
