import React from 'react'
import PropTypes from 'prop-types'
import ReactHtmlParser from 'react-html-parser'
import classNames from 'classnames'
import { cCentered } from './styles.module.scss'
import './styles.scss'

const Wysiwyg = ({ children, className, isCentered }) => {
  const wysiwygClasses = classNames({
    wysiwyg: true,
    [cCentered]: isCentered,
    [className]: !!className,
  })

  return <div className={wysiwygClasses}>{ReactHtmlParser(children)}</div>
}

Wysiwyg.propTypes = {
  children: PropTypes.node.isRequired,
  isCentered: PropTypes.bool,
}

Wysiwyg.defaultProps = {
  classNames: undefined,
  isCentered: false,
}

export default Wysiwyg
