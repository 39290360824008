import React, { useState, useEffect, useRef } from 'react'
import { Link } from 'src/components/Base'
import MenuDropdown from 'src/components/MenuDropdown'
import PropTypes from 'prop-types'

import {
  cList,
  cListElement,
  cLink,
  cActive,
  cDropdown,
} from './styles.module.scss'

// Outside Click Event Handler based on https://stackoverflow.com/questions/32553158/detect-click-outside-react-component
function useOutsideAlerter(ref, setOpenDropDown) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setOpenDropDown(null)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ref])
}

const MainMenuDesktop = ({ data }) => {
  const [openDropDown, setOpenDropDown] = useState(null)
  const wrapperRef = useRef(null)
  useOutsideAlerter(wrapperRef, setOpenDropDown)

  return (
    <nav ref={wrapperRef}>
      <ul className={cList}>
        {data
          // Filter Items without a parentId - just use parent-nodes on top-level-manu
          .filter((item) => item.parentId === null)
          .map((item, index) => {
            let childLinks = []
            if (item.childItems.nodes.length !== 0) {
              childLinks.push({
                label: item.label,
                url: item.url,
              })
              childLinks.push(...item.childItems.nodes)
            }

            return (
              <li className={cListElement} key={item.id}>
                {
                  // No children - Render Menu Link
                  item.childItems.nodes.length === 0 ? (
                    <Link
                      to={item.url}
                      className={cLink}
                      activeClassName={cActive}
                      onClick={() => {
                        setOpenDropDown(null)
                      }}
                    >
                      {item.label}
                    </Link>
                  ) : (
                    // Children - Render Dropdown Link
                    <MenuDropdown
                      className={cDropdown}
                      dropdownItems={
                        // Create Dropdown Item Child Array of Objects
                        childLinks.map((childItem) => ({
                          label: childItem.label,
                          link: childItem.url,
                          color: childItem.atspMenuItem?.menuItemColor,
                        }))
                      }
                      isOpen={openDropDown === index}
                      index={index}
                      setOpenDropDown={setOpenDropDown}
                    >
                      {item.label}
                    </MenuDropdown>
                  )
                }
              </li>
            )
          })}
      </ul>
    </nav>
  )
}

MainMenuDesktop.propTypes = {
  data: PropTypes.array.isRequired,
}

export default MainMenuDesktop
