import React from 'react'
import PropTypes from 'prop-types'
import { Container } from 'src/components/Base'
import classNames from 'classnames'
import { getImage } from 'gatsby-plugin-image'
import {
  cSection,
  cBackground,
  cHasScrollMarginTop,
} from './styles.module.scss'
import { convertToBgImage } from 'gbimage-bridge'
import BackgroundImage from 'gatsby-background-image'

const Section = ({
  children,
  backgroundImageData,
  hasBackground,
  className,
  isBoxed,
  hasScrollMarginTop,
  ...props
}) => {
  const sectionClasses = classNames({
    [cSection]: true,
    [cBackground]: hasBackground,
    [className]: !!className,
    [cHasScrollMarginTop]: hasScrollMarginTop,
  })

  const containerChildren = <Container isBoxed={isBoxed}>{children}</Container>

  let image, bgImage

  if (backgroundImageData) {
    image = getImage(backgroundImageData)
    bgImage = convertToBgImage(image)
  }

  return (
    <>
      {backgroundImageData && (
        <BackgroundImage
          Tag="section"
          className={sectionClasses}
          preserveStackingContext
          {...bgImage}
          {...props}
        >
          {containerChildren}
        </BackgroundImage>
      )}

      {!backgroundImageData && (
        <section className={sectionClasses} {...props}>
          {containerChildren}
        </section>
      )}
    </>
  )
}

Section.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  backgroundImageData: PropTypes.object,
  hasBackground: PropTypes.bool,
  isBoxed: PropTypes.bool,
  hasScrollMarginTop: PropTypes.bool,
}

Section.propTypes = {
  className: 'test',
  backgroundImageData: undefined,
  hasBackground: false,
  isBoxed: false,
  hasScrollMarginTop: false,
}

export default Section
