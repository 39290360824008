import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import {
  cButton,
  cDisabled,
  cPrimary,
  cSecondary,
  cCta,
  cPrimaryOutlined,
  cSecondaryOutlined,
  cCtaOutlined,
  cLessPadding,
  cNoMinWidht,
} from './styles.module.scss'

const Button = ({
  as: Component = 'button',
  className,
  children,
  color,
  outlined,
  disabled,
  lessPadding,
  noMinWidth,
  label,
  ...props
}) => {
  const buttonClasses = classNames({
    [cButton]: true,
    [cDisabled]: disabled,
    [cPrimary]: !outlined && color === 'primary',
    [cSecondary]: !outlined && color === 'secondary',
    [cCta]: !outlined && color === 'cta',
    [cPrimaryOutlined]: outlined && color === 'primary',
    [cSecondaryOutlined]: outlined && color === 'secondary',
    [cCtaOutlined]: outlined && color === 'cta',
    [cLessPadding]: lessPadding,
    [cNoMinWidht]: noMinWidth,
    [className]: !!className,
  })

  return (
    <Component
      label={label}
      className={buttonClasses}
      disabled={disabled}
      {...props}
    >
      {children}
    </Component>
  )
}

Button.propTypes = {
  as: PropTypes.elementType,
  color: PropTypes.string,
  outlined: PropTypes.boolean,
  disabled: PropTypes.boolean,
  children: PropTypes.node.isRequired,
  lessPadding: PropTypes.boolean,
  noMinWidth: PropTypes.boolean,
}

Button.defaultProps = {
  as: 'button',
  color: 'primary',
  outlined: false,
  disabled: false,
  lessPadding: false,
  noMinWidth: false,
}

export default Button
