import React, { createContext, useState } from 'react'
import { ContextDevTool } from 'react-context-devtool'

const defaults = {
  currentLocation: 'at',
  currentLangauge: 'de',
  currentPageTranslations: [],
}

const LanguageContext = createContext(defaults)

export const LanguageProvider = ({ children }) => {
  const [currentLocation, setCurrentLocation] = useState(
    defaults.currentLocation
  )
  const [currentLangauge, setCurrentLanguage] = useState(
    defaults.currentLangauge
  )
  const [currentPageTranslations, setCurrentPageTranslations] = useState(
    defaults.currentPageTranslations
  )

  return (
    <LanguageContext.Provider
      value={{
        currentLocation,
        setCurrentLocation,
        currentLangauge,
        setCurrentLanguage,
        currentPageTranslations,
        setCurrentPageTranslations,
      }}
    >
      <ContextDevTool
        context={LanguageContext}
        id="languageContext"
        displayName="Language Context"
      />
      {children}
    </LanguageContext.Provider>
  )
}

export default LanguageContext
