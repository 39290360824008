import 'react-app-polyfill/ie9'
import React from 'react'
import PropTypes from 'prop-types'
import { isIE } from 'react-device-detect'
import Navbar from 'src/components/Navbar'
import Topbar from 'src/components/Topbar'
import Footer from 'src/components/Footer'
import BackToTopButton from 'src/components/BackToTopButton'
import { useBreakpoint } from 'gatsby-plugin-breakpoints'
import CookieConsent from 'src/components/CookieConsent'
import IEOverlay from 'src/components/IEOverlay'
import 'normalize.css'
import './styles.scss'

import 'src/theme/theme.scss'

const Layout = ({ children }) => {
  const breakpoints = useBreakpoint()
  const trigger = isIE
  if (!isIE) {
    const isBrowser = typeof window !== 'undefined'
    if (isBrowser) {
      // check if URL is directing to old events link
      if (document.baseURI.includes('aktuelles/veranstaltung')) {
        // set new URL to current events page
        // (only temporary solution, no language slug needed)
        window.location.href = 'https://atsp.com/de-at/events'
      }
      // if no datalayer is given (Development builds I guess) create an empty array in its place
      if (window.dataLayer === undefined) {
        window.dataLayer = []
      }
    }
  }
  return (
    <>
      {trigger && <IEOverlay />}
      {!trigger && !breakpoints.sm && !breakpoints.md && !breakpoints.lg ? (
        <Topbar />
      ) : null}
      {!trigger && (
        <>
          <Navbar />
          <CookieConsent
            gtmTriggers={[
              {
                name: 'Verbesserte Nutzererfahrung',
                desc: 'Test Desc',
                event: 'trackingCookieAccepted',
              },
              {
                name: 'Targeting & Marketing',
                event: 'marketingCookieAccepted',
              },
            ]}
          />
          <main>{children}</main>
          <Footer></Footer>
          <BackToTopButton />
        </>
      )}
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
