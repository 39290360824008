const regexLocaleLocation = /^[a-z]{2}-[a-z]{2}$/
const regexLocaleLocationUri = /^\/[a-z]{2}-[a-z]{2}\//g
const regexLocationUri = /^\/[xx]{2}-[a-z]{2}\//g
const regexLocaleLocationBackend = /^\/[a-z]{2}_[a-zA-Z]{2}\/\w+/g
const regexLinkWithLocation = /^\/[a-z]{2}\/\w+/
const regexLinkWithLocaleLocation = /^\/[a-z]{2}-[a-zA-Z]{2}\/\w+/

export const currentPathHasValidFormat = () => {
  if (typeof window !== `undefined`) {
    const path = window.location.pathname
    if (path.match(regexLocaleLocationUri)) {
      return true
    }
  }
  return false
}

export const currentPathDetectBrowser = () => {
  if (typeof window !== `undefined`) {
    const path = window.location.pathname
    if (path.match(regexLocationUri)) {
      return true
    }
  }
  return false
}

export const getStringLocaleLocation = (link) => {
  const path = link.substring(1, 6)
  const match = path.match(regexLocaleLocation)
  if (match) {
    return match[0]
  }
}

export const getPathLocaleLocation = () => {
  if (typeof window !== `undefined`) {
    const path = window.location.pathname.substring(1, 6)
    const match = path.match(regexLocaleLocation)
    if (match) {
      return match[0]
    }
  }
  return null
}

export const getPathLocale = () => {
  const localeAndLocation = getPathLocaleLocation()
  if (localeAndLocation && localeAndLocation.length > 2) {
    return localeAndLocation.split('-')[0]
  }
  return null
}

export const getPathLocation = () => {
  const localeAndLocation = getPathLocaleLocation()
  if (localeAndLocation && localeAndLocation.length > 2) {
    return localeAndLocation.split('-')[1]
  }
  return null
}

export const transformLocale = (localeLocation) => {
  const parts = localeLocation.split('_')
  if (parts.length === 2) {
    return `${parts[0]}-${parts[1].toLowerCase()}`
  }
  return null
}

export const getLocationFromLocaleLocation = (localeLocation) => {
  if (localeLocation && localeLocation.length > 2) {
    return localeLocation.split('_')[1]
  }
  return null
}

export const getLanguageFromLocaleLocation = (localeLocation) => {
  if (localeLocation && localeLocation.length > 2) {
    return localeLocation.split('_')[0]
  }
  return null
}

export const isValidLocaleLocation = (validLocaleLocations, localeLocation) => {
  if (
    validLocaleLocations.find(
      (_localeLocation) => _localeLocation.locale === localeLocation
    )
  ) {
    return true
  }
  return null
}

export const getLinkWithoutLocale = (link) => {
  if (regexLocaleLocationBackend.test(link)) {
    return link.substring(6)
  }
  return link
}

export const getLinkWithoutLocaleFrontend = (link) => {
  if (regexLinkWithLocaleLocation.test(link)) {
    return link.substring(6)
  }
  return link
}

export const hasLocaleOrLocaleLocation = (link) => {
  return (
    regexLinkWithLocation.test(link) || regexLinkWithLocaleLocation.test(link)
  )
}
