import React, { useState, useEffect } from 'react'
import { Container, Link } from 'src/components/Base'
import ChevronIcon from 'src/assets/images/chevron-right-solid.inline.svg'
import { v4 as uuid } from 'uuid'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import Topbar from 'src/components/Topbar'

import {
  cMenu,
  cMenuItems,
  cMenuItem,
  cMenuLink,
  cMenuLinkActive,
  cMenuLinkSub,
  cMenuChevron,
  cMenuChevronActive,
  cMenuContainer,
} from './styles.module.scss'

const MainMenuMobile = ({ data, setMenuOpen }) => {
  const firstLevelNavLinks = data.filter((item) => item.parentId === null)
  const [navLinks, setNavLinks] = useState(firstLevelNavLinks)
  const [navHeading, setNavHeading] = useState('')
  const [navParentLink, setNavParentLink] = useState('')

  const linkClasses = classNames({
    [cMenuLink]: true,
    [cMenuLinkSub]: !!navHeading,
  })

  const activeLinkClasses = classNames({
    [cMenuLink]: true,
    [cMenuLinkActive]: true,
  })

  const activeChevronClasses = classNames({
    [cMenuChevron]: true,
    [cMenuChevronActive]: true,
  })

  useEffect(() => {
    setNavLinks(navLinks)
  }, [navLinks])

  useEffect(() => {
    setNavHeading(navHeading)
  }, [navHeading])

  useEffect(() => {
    setNavParentLink(navParentLink)
  }, [navParentLink])

  return (
    <nav className={cMenu}>
      <Container className={cMenuContainer}>
        <ul className={cMenuItems}>
          {navHeading && (
            <li key={uuid()} className={cMenuItem}>
              <button
                className={activeLinkClasses}
                onClick={() => {
                  setNavLinks(firstLevelNavLinks)
                  setNavHeading('')
                  setNavParentLink('')
                }}
              >
                <ChevronIcon className={activeChevronClasses} />
                {navHeading}
              </button>
            </li>
          )}
          {navParentLink && (
            <li key={uuid()} className={cMenuItem}>
              <Link
                to={navParentLink}
                className={linkClasses}
                onClick={() => {
                  setMenuOpen(false)
                }}
              >
                {navHeading}
                <ChevronIcon className={cMenuChevron} />
              </Link>
            </li>
          )}
          {navLinks.map((item) => (
            <li key={item.id} className={cMenuItem}>
              {item.childItems?.nodes.length === 0 ||
              item.childItems === undefined ? (
                <Link
                  to={item.url}
                  className={linkClasses}
                  onClick={() => {
                    setMenuOpen(false)
                  }}
                >
                  {item.label}
                  <ChevronIcon className={cMenuChevron} />
                </Link>
              ) : (
                <button
                  className={linkClasses}
                  onClick={() => {
                    setNavLinks(item.childItems.nodes)
                    setNavHeading(item.label)
                    setNavParentLink(item.url)
                  }}
                >
                  {item.label}
                  <ChevronIcon className={cMenuChevron} />
                </button>
              )}
            </li>
          ))}
        </ul>
      </Container>
      <Topbar setMenuOpen={setMenuOpen} />
    </nav>
  )
}

MainMenuMobile.propTypes = {
  data: PropTypes.array.isRequired,
  setMenuOpen: PropTypes.function,
}

export default MainMenuMobile
