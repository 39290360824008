import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import * as styles from './styles.module.scss'
import { Button } from 'src/components/Base'

const DropdownButton = ({
  as: Component = 'button',
  children,
  className,
  dropdownItems,
  onChange,
  dropdownLabelIndex,
  ...props
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const [dropdownLabel, setDropwdownLabel] = useState(children)

  useEffect(() => {
    setDropwdownLabel(renderDropdownText(dropdownItems[dropdownLabelIndex]))
  }, [dropdownLabelIndex])

  const buttonClasses = classNames({
    [styles.dropdownButton]: true,
    [className]: !!className,
  })

  const dropdownIconClassess = classNames({
    [styles.dropdownIcon]: true,
    [styles.dropdownIconOpen]: dropdownOpen,
  })

  const dropdownListClasses = classNames({
    [styles.dropdownList]: true,
    [styles.dropdownOpen]: dropdownOpen,
  })

  const onDropdownItemClick = (item) => {
    setDropwdownLabel(renderDropdownText(item))
    onChange(item)
  }

  const renderDropdownText = (item) => {
    return (
      <>
        {item.icon && <span className={styles.itemIcon}>{item.icon}</span>}
        {item.label}
      </>
    )
  }

  const renderDropdownItem = (item) => {
    return (
      <li onClick={() => onDropdownItemClick(item)}>
        {renderDropdownText(item)}
      </li>
    )
  }

  return (
    <Button
      lessPadding
      className={buttonClasses}
      onClick={() => setDropdownOpen(!dropdownOpen)}
      {...props}
    >
      <span className={styles.currentItem}>{dropdownLabel}</span>

      <svg
        className={dropdownIconClassess}
        width="8"
        height="6.4"
        viewBox="0 0 8 6.4"
      >
        <path
          id="navigation-arrow"
          d="M4,0,8,6.4H0Z"
          transform="translate(8 6.4) rotate(180)"
          fill="#7f7f7f"
        />
      </svg>

      <div className={dropdownListClasses}>
        {dropdownItems && (
          <ul>
            {dropdownItems.map((dropdownItem) => {
              if (dropdownItem.label || dropdownItem.icon) {
                return renderDropdownItem(dropdownItem)
              }
            })}
          </ul>
        )}
      </div>
    </Button>
  )
}

DropdownButton.propTypes = {
  as: PropTypes.elementType,
  dropdownItems: PropTypes.array.isRequired,
  dropdownLabelIndex: PropTypes.number,
}

DropdownButton.defaultProps = {
  as: 'button',
  dropdownLabelIndex: 0,
}

export default DropdownButton
