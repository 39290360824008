// extracted by mini-css-extract-plugin
export var cButton = "styles-module--c-button--7416b";
export var cCta = "styles-module--c-cta--6dd35";
export var cCtaOutlined = "styles-module--c-cta-outlined--552dc";
export var cDisabled = "styles-module--c-disabled--68011";
export var cLessPadding = "styles-module--c-less-padding--beb84";
export var cNoMinWidht = "styles-module--c-no-min-widht--b95ce";
export var cPrimary = "styles-module--c-primary--52087";
export var cPrimaryOutlined = "styles-module--c-primary-outlined--43cbb";
export var cSecondary = "styles-module--c-secondary--01adf";
export var cSecondaryOutlined = "styles-module--c-secondary-outlined--2290c";