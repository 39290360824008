import React, { useEffect, useState } from 'react'
import { isSafari } from 'react-device-detect'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import {
  heading,
  center,
  underline,
  safariUnderline,
  dark,
  light,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
} from './styles.module.scss'

const Heading = ({
  as: Component = 'h1',
  children,
  className,
  size,
  isCentered,
  color,
  branchColor,
  ...props
}) => {
  const [headlineText, setHeadlineText] = useState(children)

  let underlineColor = []
  underlineColor['--heading-underline-color'] = branchColor

  useEffect(() => {
    // ? used here to prevent "Cannot read property match of undefined" error
    // Error prevented multilanguage from working correctly
    // So not in the right branch, but in the right branch
    const res = headlineText?.match(/\*(.*?)\*/)

    if (res && res.length > 0) {
      // note: doesn't handle * in headline-text
      const underLinedText = (
        <>
          {' '}
          {headlineText.split('*')[0]}{' '}
          <span className={underlineClasses} style={underlineColor}>
            {res[1]}
          </span>{' '}
          {headlineText.split('*')[2]}
        </>
      )
      setHeadlineText(underLinedText)
    }
  }, [children])

  const headingClasses = classNames({
    [heading]: true,
    [dark]: color === 'dark',
    [light]: color === 'light',
    [h1]: size === 1,
    [h2]: size === 2,
    [h3]: size === 3,
    [h4]: size === 4,
    [h5]: size === 5,
    [h6]: size === 6,
    [center]: isCentered,
    [className]: !!className,
  })

  const underlineClasses = classNames({
    [underline]: true,
    [safariUnderline]: isSafari,
  })

  const headingStyle =
    color !== 'light' && color !== 'dark' ? { color: color } : undefined

  return (
    <Component className={headingClasses} style={headingStyle} {...props}>
      {headlineText}
    </Component>
  )
}

Heading.propTypes = {
  children: PropTypes.string.isRequired,
  as: PropTypes.elementType,
  size: PropTypes.number,
  isCentered: PropTypes.boolean,
  color: PropTypes.string,
  branchColor: PropTypes.string,
}

Heading.defaultProps = {
  as: 'h1',
  size: 1,
  isCentered: false,
  color: 'light',
  branchColor: '#4D71B3',
}

export default Heading
