import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import LanguageLocationSwitcher from 'src/components/LanguageLocationSwitcher'
import { Container, Text, Link } from 'src/components/Base'
import classNames from 'classnames'
import {
  cContainer,
  cTopBar,
  cTopBarInner,
  cText,
  cMenuContainer,
  cList,
  cListItem,
  cListLink,
  cDivider,
  cLabel,
  cNewsLine,
  cLink,
  cMenu,
  cLanguageLocationContainer,
} from './styles.module.scss'
import { v4 as uuid } from 'uuid'

const Topbar = ({ setMenuOpen }) => {
  const data = useStaticQuery(graphql`
    query NewsLineQuery {
      allWpMenu(filter: { slug: { eq: "topbarmenu" } }) {
        nodes {
          menuItems {
            nodes {
              path
              label
            }
          }
        }
      }
      allWp {
        nodes {
          themeGeneralSettings {
            atspOptions {
              newsLine {
                link {
                  title
                  url
                }
                text
              }
            }
          }
        }
      }
    }
  `)

  const { newsLine } = data.allWp.nodes[0].themeGeneralSettings.atspOptions
  const { menuItems } = data.allWpMenu.nodes[0]

  const newsLabelClasses = classNames({
    [cLabel]: true,
    'text-white': true,
  })

  const topBarMenuClasses = classNames({
    [cList]: true,
    [cMenu]: true,
  })

  return (
    <div className={cTopBar}>
      <Container className={cContainer}>
        <div className={cTopBarInner}>
          <div className={cNewsLine}>
            <span className={newsLabelClasses}>News</span>
            <Text className={cText}>{newsLine.text}</Text>
            {newsLine.link && (
              <Link to={newsLine.link.url} className={cLink}>
                {newsLine.link.title}
              </Link>
            )}
          </div>
          <div className={cMenuContainer}>
            <ul className={topBarMenuClasses}>
              {menuItems &&
                menuItems.nodes.map(({ path, label }) => (
                  <li key={uuid()} className={cListItem}>
                    <Link
                      to={path}
                      className={cListLink}
                      onClick={() => {
                        !!setMenuOpen && setMenuOpen(false)
                      }}
                    >
                      {label}
                    </Link>
                  </li>
                ))}
            </ul>
            <span className={cDivider}>|</span>
            <div className={cLanguageLocationContainer}>
              <LanguageLocationSwitcher />
            </div>
          </div>
        </div>
      </Container>
    </div>
  )
}

export default Topbar
