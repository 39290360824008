import React from 'react'

const IEOverlay = () => {
  return (
    <section style="height: 100vh; width: 100vw; background-color: #4D71B3; position: absolute; top: 0; left: 0; padding: 32px;">
      <div>
        <h1 style="color: white; font-family: sans-serif; font-size: 3rem; font-weight: bold;">
          Ihr Browser wird nicht unterstützt.
        </h1>
        <p style="color: white; font-family: sans-serif;">
          Die Webseite von ATSP ist nicht mehr für die Darstellung mit Internet
          Explorer optimiert.
        </p>
        <p style="color: white; font-family: sans-serif;">
          Bitte wechseln Sie, wenn möglich, zu einem aktuellen Browser wie
          Google Chrome, Mozilla Firefox oder Microsoft Edge.
        </p>
      </div>
    </section>
  )
}

export default IEOverlay
