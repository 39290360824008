import React, { useState, useEffect, useContext } from 'react'
import { Container, Link } from 'src/components/Base'
import MainMenuDesktop from 'src/components/Menues/MainMenuDesktop'
import MainMenuMobile from 'src/components/Menues/MainMenuMobile'
import { useStaticQuery, graphql } from 'gatsby'
import { useBreakpoint } from 'gatsby-plugin-breakpoints'
import classNames from 'classnames'
import LanguageContext from 'src/context/language'
import { getLinkWithoutLocaleFrontend } from 'src/utils/pathUtils'

import {
  cHeader,
  cContainer,
  cBrand,
  cMenuBar,
  cMenuToggle,
  cMenuClose,
  cHeaderIsFixed,
} from './styles.module.scss'

const debugNavbar = false

const Navbar = () => {
  const data = useStaticQuery(graphql`
    query menuQuery {
      allWpMenu {
        nodes {
          name
          locations
          slug
          id
          menuItems {
            nodes {
              url
              label
              id
              target
              parentId
              childItems {
                nodes {
                  id
                  label
                  url
                }
              }
            }
          }
        }
      }
      allWpPage(
        filter: { template: { templateName: { eq: "Template-Branche" } } }
      ) {
        nodes {
          title
          uri
          atspBranchOptions {
            branchColor
          }
        }
      }
      allWp {
        edges {
          node {
            themeGeneralSettings {
              atspOptions {
                optionslogo {
                  id
                  localFile {
                    publicURL
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const breakpoints = useBreakpoint()
  const scrollTrigger = 50
  const [isFixed, stIsFixed] = useState(false)
  const [menuOpen, setMenuOpen] = useState(false)
  const [menuLinks, setMenuLinks] = useState([])

  const branchData = data.allWpPage.nodes
  const logo =
    data.allWp.edges[0].node.themeGeneralSettings.atspOptions.optionslogo
      .localFile.publicURL

  const menuCloseClasses = classNames({
    [cMenuBar]: true,
    [cMenuClose]: true,
  })

  const headerClasses = classNames({
    [cHeader]: true,
    [cHeaderIsFixed]: isFixed,
  })

  const languageContext = useContext(LanguageContext)

  debugNavbar && console.log('Language Context Navbar')
  debugNavbar && console.log(languageContext)

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  })

  useEffect(() => {
    setMenuLinks(createColorMenu())
  }, [languageContext])

  const handleScroll = () => {
    let scrollPosition = window.pageYOffset

    if (scrollPosition >= scrollTrigger) {
      stIsFixed(true)
    } else {
      stIsFixed(false)
    }
  }

  const createColorMenu = () => {
    /* REGEX to filter all menues starting with MAIN_NAVIGATION */
    const mainMenuRegex = /MAIN_NAVIGATION\w*/
    const mainMenues = data.allWpMenu.nodes
      .filter((item) => {
        /* Filter for Items that match the regex pattern => So they are Main Navigations */
        return item.locations.some((e) => mainMenuRegex.test(e))
      })
      .filter((menu) => {
        // Filter for the right language
        return menu.locations.some((location) => {
          // In de_at, there is no added slug for our Navigation Location. Thats bad. To change this, we would have to change the backend
          // which might destroy the whole language switch process
          // So to stay save, i did this quick hack which I am not proud of.
          // If you have any better solutions, just tell.
          if (
            `${languageContext.currentLangauge}_${languageContext.currentLocation}` ===
            'de_at'
          ) {
            return location.slice(-5).toLowerCase() === 'ation'
          } else {
            return (
              location.slice(-5).toLowerCase() ===
              `${languageContext.currentLangauge}_${languageContext.currentLocation}`
            )
          }
        })
      })
      .map((mainMenu) => {
        /* Backend delivers URL strings as de_de, we need de-de */
        mainMenu.menuItems.nodes.map((node) => {
          node.url = node.url.replace('_', '-')
          node.url = `${getLinkWithoutLocaleFrontend(node.url)}`
          return node
        })
        return mainMenu
      })

    return mainMenues[0].menuItems.nodes.map((item) => {
      // Only use parent-items
      if (item.parentId === null) {
        // Only use parent items that have children
        if (item.childItems.nodes.length > 0) {
          // for all children
          item.childItems.nodes.map((childItem) => {
            // check if the URL of the item is also contained in the branch-data
            branchData.forEach((data) => {
              // Set color, if the url matches
              if (data.uri === childItem.url) {
                childItem.atspMenuItem = {
                  menuItemColor: data.atspBranchOptions.branchColor,
                }
              }
            })
            // For the Child Item in the Menu, we set the current Language Location as base for the link
            // This is needed, because in the backend we don't know, if there are any translations for the pages
            childItem.url = `${getLinkWithoutLocaleFrontend(childItem.url)}`
            return childItem
          })
        }
      }
      return item
    })
  }

  return (
    <header className={headerClasses}>
      <Container className={cContainer}>
        <Link to={`/`}>
          <img className={cBrand} src={logo} alt="Home" />
        </Link>
        {!breakpoints.lg && (
          <MainMenuDesktop data={menuLinks}></MainMenuDesktop>
        )}
        {breakpoints.lg && (
          <button
            title="Menu Button"
            className={cMenuToggle}
            onClick={() => {
              setMenuOpen(!menuOpen)
            }}
          >
            {!menuOpen ? (
              <>
                <span className={cMenuBar}></span>
                <span className={cMenuBar}></span>
                <span className={cMenuBar}></span>
              </>
            ) : (
              <>
                <span className={menuCloseClasses}></span>
                <span className={menuCloseClasses}></span>
              </>
            )}
          </button>
        )}
      </Container>
      {menuOpen && breakpoints.lg && (
        <MainMenuMobile
          data={menuLinks}
          setMenuOpen={setMenuOpen}
        ></MainMenuMobile>
      )}
    </header>
  )
}

export default Navbar
