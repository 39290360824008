// extracted by mini-css-extract-plugin
export var center = "styles-module--center--cd956";
export var dark = "styles-module--dark--1a83d";
export var h1 = "styles-module--h1--e2e9d";
export var h2 = "styles-module--h2--86ca9";
export var h3 = "styles-module--h3--62bff";
export var h4 = "styles-module--h4--ea1e7";
export var h5 = "styles-module--h5--1fa1f";
export var h6 = "styles-module--h6--037fb";
export var heading = "styles-module--heading--e813d";
export var light = "styles-module--light--db39e";
export var safariUnderline = "styles-module--safari-underline--ad042";
export var underline = "styles-module--underline--3b1b7";