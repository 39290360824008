// extracted by mini-css-extract-plugin
export var cCopyright = "styles-module--c-copyright--2ab4a";
export var cFooter = "styles-module--c-footer--57b09";
export var cFooterCol = "styles-module--c-footer-col--0385b";
export var cFooterSection = "styles-module--c-footer-section--a3c92";
export var cHeading = "styles-module--c-heading--56f0e";
export var cLink = "styles-module--c-link--bd9f9";
export var cList = "styles-module--c-list--f1bfd";
export var cListItem = "styles-module--c-list-item--73d39";
export var cSocialBar = "styles-module--c-social-bar--58e05";
export var cSocialIcon = "styles-module--c-social-icon--9952e";
export var cSocialLink = "styles-module--c-social-link--4d447";
export var cSocket = "styles-module--c-socket--334f4";
export var cSocketMenu = "styles-module--c-socket-menu--662c7";
export var cSocketMenuList = "styles-module--c-socket-menu-list--520d3";
export var cSocketMenuListItem = "styles-module--c-socket-menu-list-item--af71a";