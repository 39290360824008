import React, { useContext, useState, useEffect } from 'react'
import { useStaticQuery, graphql, navigate } from 'gatsby'
import {
  getLocationFromLocaleLocation,
  getLanguageFromLocaleLocation,
  getPathLocaleLocation,
  getPathLocale,
  getPathLocation,
  isValidLocaleLocation,
} from 'src/utils/pathUtils'
import LanguageContext from 'src/context/language'
import DropdownButton from 'src/components/Base/DropdownButton'
import Cookies from 'js-cookie'

import ATFlag from 'src/assets/images/at.inline.svg'
import DEFlag from 'src/assets/images/de.inline.svg'
import CHFlag from 'src/assets/images/ch.inline.svg'

import { cDropdownButton, cFlag, cList } from './styles.module.scss'

const debugSwitcher = false

const COOKIE_LANGUAGE_NAME = 'atsp_preffered_language'
const COOKIE_LOCATION_NAME = 'atsp_preffered_location'
const COOKIE_OPTIONS = { path: '/', expires: 365, sameSite: 'strict' }

const LanguageLocationSwitcher = () => {
  /* ************ */
  /* Set Default Values & States
  /* ************ */

  const languageContext = useContext(LanguageContext)

  const [
    locationOrLanguageHasChanged,
    setLocationOrLanguageHasChanged,
  ] = useState(false)

  debugSwitcher &&
    console.log(
      `Currents: ${languageContext.currentLangauge}, ${languageContext.currentLocation}`
    )

  const [currentLocation, setCurrentLocation] = useState('AT')
  const [currentLangauge, setCurrentLanguage] = useState('de')
  const [languageIndex, setLanguageIndex] = useState(0)
  const [locationIndex, setLocationIndex] = useState(0)

  /* ************ */
  /* Get Languages from WP Backend
  /* ************ */

  const languages = useStaticQuery(graphql`
    query AvailableLanguageQuery {
      wp {
        languages {
          name
          locale
        }
      }
    }
  `)

  /* ************ */
  /* Language Array Gets Built
  /* ************ */

  let languageArr = []
  // Map WordPress Languages
  const languageOptions = languages.wp.languages.map((languageLocation) => {
    // Use function from Path Utils to get Language from Location
    const language = getLanguageFromLocaleLocation(languageLocation.locale)

    // Add to language Array, if it is not included
    if (!languageArr.includes(language)) {
      languageArr.push(language)

      // Map returns Language Object to LanguageOptions
      return {
        label: getLanguageFromLocaleLocation(languageLocation.locale),
        value: getLanguageFromLocaleLocation(languageLocation.locale),
        icon: false,
      }
    }
    // Or returns false if there is no language
    return false
  })

  /* ************ */
  /* Location Array Gets Built, similar to Language Array
  /* ************ */

  let locationArr = []
  const locationOptions = languages.wp.languages.map((languageLocation) => {
    const location = getLocationFromLocaleLocation(languageLocation.locale)

    if (!locationArr.includes(location)) {
      locationArr.push(location)

      let icon = false

      switch (location) {
        case 'AT':
          icon = <ATFlag className={cFlag} />
          break
        case 'DE':
          icon = <DEFlag className={cFlag} />
          break
        case 'CH':
          icon = <CHFlag className={cFlag} />
          break
      }

      return {
        label: '',
        value: location,
        icon: icon,
      }
    }

    return false
  })

  /* ************ */
  /* Changes handling
  /* ************ */

  const onLanguageChange = (e) => {
    setCurrentLanguage(e.value)
    setLanguageCookie(e.value)
    setLocationOrLanguageHasChanged(true)
  }

  const onLocationChange = (e) => {
    setCurrentLocation(e.value)
    setLocationCookie(e.value)
    setLocationOrLanguageHasChanged(true)
  }

  const setLanguageCookie = (language) => {
    Cookies.set(COOKIE_LANGUAGE_NAME, language, COOKIE_OPTIONS)
    if (debugSwitcher) {
      console.debug('[LanguageLocationSwitcher] Language cookie set')
    }
  }

  const setLocationCookie = (location) => {
    Cookies.set(COOKIE_LOCATION_NAME, location, COOKIE_OPTIONS)
    if (debugSwitcher) {
      console.debug('[LanguageLocationSwitcher] Location cookie set')
    }
  }

  /* ************ */
  /* Effect Listening on changes of current Language or Location
  /* ************ */

  useEffect(() => {
    // If there is a currentLanguage, a currentLocation and the locationOrLanguage has changed
    if (currentLangauge && currentLocation && locationOrLanguageHasChanged) {
      // We build a new String with the language and the location
      const localeLocation = `${currentLangauge}_${currentLocation}`
      debugSwitcher &&
        console.log(
          'localeLocation => provided by Language & Location Switcher:'
        )
      debugSwitcher && console.log(localeLocation)
      debugSwitcher &&
        console.log(
          'languages.wp.languages => All possible Language & Locations from Backend:'
        )
      debugSwitcher && console.log(languages.wp.languages)

      // If the newly built location is valid
      if (isValidLocaleLocation(languages.wp.languages, localeLocation)) {
        debugSwitcher &&
          console.log(
            'Location is valid - We have the L&L Combination in Backend'
          )
        debugSwitcher &&
          console.log('We check the current translations for this page:')
        debugSwitcher && console.log(languageContext.currentPageTranslations)
        // If the link the user has choosen is a value in currentPageTranslations
        const link = languageContext.currentPageTranslations.find(
          (currentPageTranslation) =>
            currentPageTranslation.locale === localeLocation
        ).link

        // We navigate to the link
        if (link) {
          debugSwitcher &&
            console.log(
              'There is an link for the L&L in the current page location:'
            )
          debugSwitcher && console.log(link)
          /* Update language Context */
          debugSwitcher && console.log('Update Language Context')
          languageContext.setCurrentLanguage(currentLangauge.toLowerCase())
          languageContext.setCurrentLocation(currentLocation.toLowerCase())
          // Fallback if wrong links are being set (language-slug wise)
          if (link === '/de-ch/de-ch') {
            document.location.href = 'https://atsp.com/de-ch'
          } else if (link === '/de-de/de-de/') {
            document.location.href = 'https://atsp.com/de-de'
          } else if (link === '/de-at/de-at/') {
            document.location.href = 'https://atsp.com/de-at'
          }
          navigate(link)
        } else {
          // fallback - TODO NOT WORKING
          debugSwitcher && console.log('We have no link')
        }
      } else {
        // fallback
        debugSwitcher && console.log('We have no valid location')
      }
    }
  }, [currentLangauge, currentLocation])

  /* ************ */
  /* Initial Effect setting Language or Location based on URL
  /* ************ */

  useEffect(() => {
    // Check if current LL and LL in Path match
    let pathLocale = getPathLocale()
    let pathLocation = getPathLocation()
    let initialLanguageLocale = `${currentLangauge.toLowerCase()}-${currentLocation.toLowerCase()}`
    let pathLanguageLocale = getPathLocaleLocation()
    debugSwitcher &&
      console.log(
        `Initial Values for Language and Location: ${initialLanguageLocale}`
      )
    debugSwitcher &&
      console.log(`Language and Location in URL: ${pathLanguageLocale}`)

    if (initialLanguageLocale === pathLanguageLocale) {
      debugSwitcher &&
        console.log(
          `Initial Values for Language and Location and Path Values are the same, that is good`
        )
    } else {
      debugSwitcher &&
        console.log(
          `Initial Values for Language and Location and Path Values are different`
        )
      debugSwitcher && console.log('Language Options: ', languageOptions)
      debugSwitcher && console.log('Location Options: ', locationOptions)

      let newPathLanguageIndex = languageOptions.findIndex(
        (x) => x.value?.toLowerCase() === pathLocale?.toLowerCase()
      )
      let newPathLocationIndex = locationOptions.findIndex(
        (x) => x.value?.toLowerCase() === pathLocation?.toLowerCase()
      )

      debugSwitcher &&
        console.log(`Index of Language Array to start: ${newPathLanguageIndex}`)
      debugSwitcher &&
        console.log(`Index of Location Array to start: ${newPathLocationIndex}`)

      if (newPathLanguageIndex !== -1 && newPathLocationIndex !== -1) {
        // Set the language index, the location index and the current vaules
        setLanguageIndex(newPathLanguageIndex)
        setLocationIndex(newPathLocationIndex)

        // this is needed for creating translations initially
        setCurrentLanguage(pathLocale)
        setCurrentLocation(pathLocation)
        setLocationOrLanguageHasChanged(true)
      } else {
        debugSwitcher && console.log(`Language-Locale-Combination not existing`)
      }
    }
  }, [])

  return (
    <>
      <ul className={cList}>
        <li>
          <DropdownButton
            className={cDropdownButton}
            onChange={onLanguageChange}
            dropdownItems={languageOptions}
            dropdownLabelIndex={languageIndex}
          />
        </li>
        <li>
          <DropdownButton
            className={cDropdownButton}
            onChange={onLocationChange}
            dropdownItems={locationOptions}
            dropdownLabelIndex={locationIndex}
          />
        </li>
      </ul>
    </>
  )
}

export default LanguageLocationSwitcher
