import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
import {
  cBackToTopButton,
  cBackToTopIcon,
  cBackToTopButtonVisible,
} from './styles.module.scss'
import ChevronIcon from 'src/assets/images/chevron.inline.svg'
import { Button } from 'src/components/Base'
import { scrollToTop } from 'src/utils/helper.js'

const BackToTopButton = () => {
  const scrollTrigger = 500
  const [isVisible, setIsVisible] = useState(false)

  const buttonClasses = classNames({
    [cBackToTopButton]: true,
    [cBackToTopButtonVisible]: isVisible,
  })

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  })

  const handleScroll = () => {
    let scrollPosition = window.pageYOffset

    if (scrollPosition >= scrollTrigger) {
      setIsVisible(true)
    } else {
      setIsVisible(false)
    }
  }

  return (
    <Button onClick={scrollToTop} className={buttonClasses} color="cta">
      <ChevronIcon className={cBackToTopIcon} />
    </Button>
  )
}

export default BackToTopButton
