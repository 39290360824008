import React, { useContext, useEffect } from 'react'
import Layout from './src/components/Layout'
import LanguageContext, { LanguageProvider } from './src/context/language'

import {
  getLocationFromLocaleLocation,
  getLanguageFromLocaleLocation,
  getLinkWithoutLocale,
  hasLocaleOrLocaleLocation,
  transformLocale,
  getPathLocale,
  getPathLocation,
} from 'src/utils/pathUtils'

const WrapLayout = ({ element, location }) => {
  const { pageContext } = element.props
  const languageContext = useContext(LanguageContext)

  // Set language context based on path
  let pathLocale = getPathLocale() || "de"
  let pathLocation = getPathLocation() || "at"

  languageContext.setCurrentLanguage(pathLocale)
  languageContext.setCurrentLocation(pathLocation)

  useEffect(() => {
    const link = getLinkWithoutLocale(pageContext.link)

    // Check if the translation for the current locale was already added, if not, push it
    // TODO: Remove ? here when there are translations for every page / event / job
    if (
      !pageContext.translations?.some(
        (e) =>
          e.locale ===
          `${getLanguageFromLocaleLocation(
            pageContext.locale
          )}_${getLocationFromLocaleLocation(pageContext.locale)}`
      )
    ) {
      // Add current link and location to Context => needed, if you want to go back
      // TODO: Remove ? here when there are translations for every page / event / job
      pageContext.translations?.push({
        link: `/${getLanguageFromLocaleLocation(
          pageContext.locale
        )}-${getLocationFromLocaleLocation(
          pageContext.locale
        )?.toLowerCase()}${link}`,
        locale: `${getLanguageFromLocaleLocation(
          pageContext.locale
        )}_${getLocationFromLocaleLocation(pageContext.locale)}`,
      })
    }

    // Set all of the page translations
    languageContext.setCurrentPageTranslations(
      // TODO: Remove ? here when there are translations for every page / event / job
      pageContext.translations?.map((translation) => ({
        // If the translation link has no language or language-locale (this is basically the home-link for AT), we add it to match the given pattern
        link: hasLocaleOrLocaleLocation(translation.link)
          ? translation.link
          : `/${transformLocale(translation.locale)}${translation.link}`,
        locale: translation.locale,
      }))
    )
  }, [location])
  // Location is changed every time, a route changes. This effect runs, every time a route changes and sets the new page translations

  return <Layout>{element}</Layout>
}

const WrapPageElement = ({ element, location }) => (
  <LanguageProvider>
    <WrapLayout element={element} location={location} />
  </LanguageProvider>
)

export default WrapPageElement
