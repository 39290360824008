import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { graphql, useStaticQuery } from 'gatsby'
import Cookies from 'js-cookie'
import CookieBanner from './Banner'
import CookieSettings from './Settings'
import { triggerTagManagerEvent } from 'src/utils/gtm.js'

const CookieConsent = ({ cookieName, cookieSettingsDefaultCookieText }) => {
  const data = useStaticQuery(graphql`
    query cookieNoticeQuery {
      wp {
        themeGeneralSettings {
          cookieNotice {
            cookies {
              cookieName
              descending
              infotext
            }
            cookieBanner {
              acceptButton
              bannerText
              cookieSettingsButton
              heading
            }
            cookieSettings {
              acceptAllButton
              essentialCookiesText
              essentialCookiesInfoText
              rejectAllButton
              saveSettingsButton
              settingsHeading
              settingsText
              cookieGuidelinesLink {
                title
                target
                url
              }
            }
          }
        }
      }
    }
  `)
  const cookieBanner = data.wp.themeGeneralSettings.cookieNotice.cookieBanner
  const cookieSettings =
    data.wp.themeGeneralSettings.cookieNotice.cookieSettings

  const [isBannerVisible, setBannerVisible] = useState(!Cookies.get(cookieName))
  const [isSettingVisible, setSettingVisible] = useState(false)
  let gtmTriggers = data.wp.themeGeneralSettings.cookieNotice.cookies

  gtmTriggers[0]['event'] = 'userExperience'
  gtmTriggers[1]['event'] = 'targetingMarketing'

  const init = () => {
    Cookies.set(cookieName, true, { expires: 14 })
    Cookies.set('gatsby-gdpr-google-tagmanager', true)
  }

  const acceptAllCookies = () => {
    init()
    Cookies.set(`${cookieName}-events`, ['allCookiesAccepted'], { expires: 14 })
    hideUI()
    location.reload()
  }

  const rejectAllCookies = () => {
    Cookies.set(cookieName, false, { expires: 1 })
    Cookies.set('gatsby-gdpr-google-tagmanager', false)
    hideUI()
  }

  const saveAndExitSettings = (events) => {
    init()
    Cookies.set(`${cookieName}-events`, events, { expires: 14 })
    hideUI()
    location.reload()
  }

  const triggerEvents = (events) => {
    events.forEach((event) => {
      triggerTagManagerEvent(event)
    })
  }

  const toggleSettings = () => {
    setBannerVisible(false)
    setSettingVisible(!isSettingVisible)
  }

  const hideUI = () => {
    setSettingVisible(false)
    setBannerVisible(false)
  }

  const checkCookie = () => {
    const events = Cookies.get(`${cookieName}-events`)
    if (events) {
      triggerEvents(JSON.parse(events))
    }
  }

  useEffect(() => {
    checkCookie()
  })

  return (
    <>
      {isBannerVisible && (
        <CookieBanner
          acceptAllCookies={acceptAllCookies}
          toggleSettings={toggleSettings}
          heading={cookieBanner.heading}
          text={cookieBanner.bannerText}
          buttonTextAccept={cookieBanner.acceptButton}
          buttonTextSettings={cookieBanner.cookieSettingsButton}
        />
      )}
      {isSettingVisible && (
        <CookieSettings
          acceptAllCookies={acceptAllCookies}
          toggleSettings={toggleSettings}
          rejectAllCookies={rejectAllCookies}
          cookies={gtmTriggers}
          saveAndExitSettings={saveAndExitSettings}
          heading={cookieSettings.settingsHeading}
          text={cookieSettings.settingsText}
          acceptAllButtonText={cookieSettings.acceptAllButton}
          rejectAllButtonText={cookieSettings.rejectAllButton}
          saveButtonText={cookieSettings.saveSettingsButton}
          defaultCookiesText={cookieSettingsDefaultCookieText}
          defaultCookiesInfoText={cookieSettings.essentialCookiesInfoText}
          cookieGuidelinesLink={cookieSettings.cookieGuidelinesLink}
        />
      )}
    </>
  )
}

CookieConsent.propTypes = {
  cookieName: PropTypes.string,
  gtmTriggers: PropTypes.array,
}

CookieConsent.defaultProps = {
  cookieName: 'cookie-banner',
  gtmTriggers: [],
}

export default CookieConsent
