import React from 'react'
import classNames from 'classnames'
import * as styles from './styles.module.scss'
import { cStretchCards } from './styles.module.scss'
import PropTypes from 'prop-types'

const FlexboxRow = ({ children, stretchCards, className, ...props }) => {
  const rowClasses = classNames({
    [styles.row]: true,
    [className]: !!className,
    [cStretchCards]: stretchCards,
  })

  return (
    <div className={rowClasses} {...props}>
      {children}
    </div>
  )
}

FlexboxRow.propTypes = {
  stretchCards: PropTypes.boolean,
}

FlexboxRow.defaultProps = {
  stretchCards: false,
}

export default FlexboxRow
