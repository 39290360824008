import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Button, Link } from 'src/components/Base'
import { v4 as uuid } from 'uuid'

import {
  cDropdownButton,
  cDropdownIcon,
  cDropdownIconOpen,
  cDropdownListContainer,
  cDropdownListContainerOpen,
  cDropdownListContainerColliding,
  cDropdownList,
  cDropdownListElement,
  cCurrentItem,
  cCurrentItemOpen,
  cDropdownLink,
} from './styles.module.scss'

const MenuDropdown = ({
  children,
  className,
  dropdownItems,
  onChange,
  isOpen,
  setOpenDropDown,
  index,
  ...props
}) => {
  const [dropdownColliding, setDropdownColliding] = useState(false)

  const dropDownRef = useRef()

  const buttonClasses = classNames({
    [cDropdownButton]: true,
    [className]: !!className,
  })

  const dropdownIconClassess = classNames({
    [cDropdownIcon]: true,
    [cDropdownIconOpen]: isOpen,
  })

  const dropdownListClasses = classNames({
    [cDropdownListContainer]: true,
    [cDropdownListContainerOpen]: isOpen,
    [cDropdownListContainerColliding]: dropdownColliding,
  })

  const dropdownItemClasses = classNames({
    [cCurrentItem]: true,
    [cCurrentItemOpen]: isOpen,
  })

  useEffect(() => {
    const changeDropDownPosition = () => {
      // Set Element to Block, otherwise you wont get width and position
      dropDownRef.current.style.display = 'block'
      // save position of right end of the element
      let viewportOffset = dropDownRef.current.getBoundingClientRect().right
      dropDownRef.current.style.removeProperty('display')
      // If right position is larger than the window-width, it would overflow
      if (viewportOffset > window.innerWidth) {
        setDropdownColliding(true)
      } else {
        setDropdownColliding(false)
      }
    }
    // Use function at the start and add event listener to window change
    changeDropDownPosition()
    window.addEventListener('resize', changeDropDownPosition)

    // If component would unmount, we remove the listener
    return () => window.removeEventListener('resize', changeDropDownPosition)
  }, [])

  return (
    <Button
      className={buttonClasses}
      onClick={() => (isOpen ? setOpenDropDown(null) : setOpenDropDown(index))}
      {...props}
    >
      <span className={dropdownItemClasses}>{children}</span>
      <svg
        className={dropdownIconClassess}
        width="8"
        height="6.4"
        viewBox="0 0 8 6.4"
      >
        <path
          id="navigation-arrow"
          d="M4,0,8,6.4H0Z"
          transform="translate(8 6.4) rotate(180)"
          fill="#7f7f7f"
        />
      </svg>
      <div className={dropdownListClasses} ref={dropDownRef}>
        {dropdownItems && (
          <ul className={cDropdownList}>
            {dropdownItems.map((dropdownItem) => (
              <li key={uuid()} className={cDropdownListElement}>
                <Link
                  to={dropdownItem.link}
                  className={cDropdownLink}
                  barColor={dropdownItem.color}
                >
                  {dropdownItem.label}
                </Link>
              </li>
            ))}
          </ul>
        )}
      </div>
    </Button>
  )
}

MenuDropdown.propTypes = {
  dropdownItems: PropTypes.array.isRequired,
}

export default MenuDropdown
